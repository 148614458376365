import { Link } from "react-router-dom"
import '../dashboard/dashboardstyles.css'
import './projectstyles/projectsummarystyle.css'
import { useState } from "react"

const moment = require('moment')

function Projectsummary({project}){
    //console.log(project.Date)
    //const AuthorInitials =   project.AuthorFirstName == undefined ? 'AA' : `${project.AuthorFirstName[0]}${project.AuthorLastName[0]}`
    const [vote, setVote] = useState(0)



    let day = ''
    let post = {
        subject: project.subject,
        AuthorInitials: 'AA',
        author: 'Sample'
    }
    if(project.authorFirstName){   
        day = moment(project.Date.toDate()).startOf('minute').fromNow()
        //console.log(project)
        post ={
            subject: project.subject,
            initials: project.authorInitials,
            author: project.authorFirstName || post.author
        }       
    }

    const ClickUpVote=(e)=>{
        //console.log(e)
        setVote(vote+1)
        e.preventDefault()
    }
    const ClickDownVote=(e)=>{
        //console.log(e)
        setVote(vote-1)
        e.preventDefault()
    }
    return(
       
        <div className="card z-depth-0 project-summary">
            <div className="card-content grey-text text-darken-3">
               
                    <div className="">
                        <span className="card-title">{post.subject}</span>
                        <p><span>by {post.author} </span></p>
                        <p className="grey-text">Sent {day}</p> 
                        <div className="btn-floating halfway-fab waves-light  translate grey darken-2"><div className="initials">{post.initials}</div></div>
                    </div>

                    {/* <div className="col s6 center vote-container">
                        <button onClick={(e)=> ClickUpVote(e)} className="clear-format"><i class="material-icons">arrow_upward</i> </button>
                            <span className="vote-tally">{vote}</span>
                        <button onClick={(e)=>ClickDownVote(e)} className="clear-format"><i class="material-icons">arrow_downward</i></button>
                    </div> */}
               
            </div>
         
        </div>  
      
    )
}

export default Projectsummary