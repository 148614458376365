import { useState, useTransition } from "react"
import  {createComment}  from '../../../store/actions/projectActions'
import { connect } from "react-redux"
import {compose} from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import CommentDetails from './CommentDetails'
const Comment=(props)=>{
   /// console.log(props.comments)
    const [commentvalue, setCommentvalue] = useState({comment: '', parentID: props.props.id})
    //console.log(parent.props.id)

    const Change=(e)=>{ 
        e.preventDefault()
        setCommentvalue({...commentvalue, comment: e.target.value })
    }
   
    const Submit=(e)=>{
        e.preventDefault()
        console.log(commentvalue)
        if(commentvalue.comment.length ==0)return alert('try typing something')
        props.createComment(commentvalue)        
    }

    const comments = props.comments
    // console.log(comment)

    const IsLoaded=({comments})=>{
        if(typeof comments != 'undefined'){ 
            console.log('comments are defined')
            return comments.map((comment)=> {
                if(comment.parentID == commentvalue.parentID) {
                   return <CommentDetails comment = {comment} />
                } else { }
             
         })
        }     
        else {
            console.log('the comments are not defined')
            return
        }
    }

    return(
<>
    <div className='row'>
        <form onSubmit={(e)=> Submit(e)} className='input-field' type="input-field">
            <input id= 'comment' type = 'text' onChange={(e)=>Change(e)}></input>
            <label for="icon_prefix">add a comment</label>
            <div className='input-field'>
                <button className='btn pink lighten-1 z-depth-0'> Post</button>
            </div>
            <span></span>

       
        </form>
    </div>


    <div className='container section project-details' >
        <div className='card z-depth-0' style = {{padding: '10px'}}>
            <div className='card-contenet'>
            <div className="title">
                <p>Coment section</p>
            </div>                    
            </div>
        </div>
        <div>
            <IsLoaded comments = {comments}/>
        </div>
    </div>
</>
    
    )
}
const mapStateToProps=(state, ownProps)=>{
    //console.log(state.firestore.ordered)
    return{
        comments: state.firestore.ordered.comments
    }
}


// for dispatching actions to the store
const matchDispatchToProps= (dispatch)=>{
    return {
    createComment: (comment)=> dispatch(createComment(comment))
        //now we will be able to call props.createProject(props)
        //the project that is passed in will then be passed to creeteProject
    }    
}
//export default connect(mapStateToProps,matchDispatchToProps)(Comment);
export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    firestoreConnect([
        {collection: 'comments'}
    ])
)(Comment)

//parentID','GrbVpecU85IZYNEX00RK