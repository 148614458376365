import moment from "moment"
export const CommentDetail=({comment})=>{
    
    return(
        <div className="card-content  section">
            <div>
            <span className="text darken-2  ">{comment.authorFirstName}</span><span className="card-action  grey-text"> {moment(comment.Date.toDate()).startOf().fromNow()}</span>
            </div>
            <div>
            <p>{comment.message}</p>
            </div>
            
        </div>
    )
}

export default CommentDetail